<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        @click="onNewClicked"
        class="mb-2"
        color="secondary"
        dark
        v-on="on"
      >
        {{ $t("createSubscriptionPlan") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">
          {{
            item.isEdit
              ? $t("editSubscriptionPlan")
              : $t("createSubscriptionPlan")
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('name')"
                v-model="name"
                color="#59D79D"
                :hide-details="errors.length === 0"
                :error-count="errors.length"
                :error-messages="errors"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <vue-editor v-model="description"></vue-editor>
              <!--<v-textarea rows="1"
                          row-height="15"
                          auto-grow
                          color="success"
                          :hide-details="descriptionErrors.length === 0"
                          :error-count="descriptionErrors.length"
                          :error-messages="descriptionErrors"
                          :label="$t('description')"
                          v-model="description"
              ></v-textarea>-->
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="number"
                :label="$t('cost')"
                v-model="cost"
                color="#59D79D"
                :hide-details="costErrors.length === 0"
                :error-count="costErrors.length"
                :error-messages="costErrors"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="popular"
                :label="$t('popular')"
                hide-details
                color="#59D79D"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="save" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'SubscriptionPlansDialog',
  components: { CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'item', 'onAdded', 'newItem'],
  mixins: [errorDialog],
  data () {
    return {
      name: '',
      errors: [],
      loading: false,
      descriptionErrors: [],
      costErrors: [],
      description: '',
      cost: '',
      popular: false
    };
  },
  watch: {
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.name = this.item.name;
        this.cost = this.item.cost;
        this.description = this.item.description;
        this.popular = this.item.popular;
        this.isEdit = this.item.isEdit;
      }
    }
  },
  methods: {
    onNewClicked () {
      this.reset();
      this.newItem();
    },
    reset () {
      this.name = '';
      this.cost = '';
      this.description = '';
      this.popular = false;
    },
    resetErrors () {
      this.errors = [];
      this.descriptionErrors = [];
      this.costErrors = [];
    },
    save () {
      this.resetErrors();
      if (this.name === '') {
        this.errors.push(this.$t('fieldNameRequired'));
      }
      if (this.description === '') {
        this.descriptionErrors.push(this.$t('fieldDescriptionRequired'));
      }
      if (this.cost === '') {
        this.costErrors.push(this.$t('fieldCostRequired'));
      }
      const formData = {
        name: this.name,
        cost: this.cost,
        description: this.description,
        popular: this.popular
      };
      if (!this.item.isEdit) {
        this.loading = true;
        const url = process.env.VUE_APP_BASE_URL + '/api/subscriptions';
        axios
          .post(url, formData)
          .then(() => {
            this.loading = false;
            this.onClose();
            this.onAdded();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      } else {
        this.loading = true;
        const url =
          process.env.VUE_APP_BASE_URL + '/api/subscriptions/' + this.item.id;
        axios
          .put(url, formData)
          .then(() => {
            this.loading = false;
            this.onAdded();
            this.onClose();
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
