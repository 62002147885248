<template>
  <v-main class="container-prosker">
    <delete-warning
      :confirm-delete="confirmDelete"
      :on-close="onDeleteClose"
      :show-delete="showDelete"
      :loading="loading"
    />
    <v-data-table
        calculate-widths
        :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
        :headers="headers"
        :items="subscriptionPlansList"
        class="elevation-1 mt-5"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('subscriptionPlan') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <subscription-plans-dialog
                :item="editedItem"
                :on-close="onAddClose"
                :new-item="newItem"
                :show-add="showAdd"
                :on-added="onAddCallback"
            />
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-alert
            :value="true"
            color="error"
            icon="warning"
            class="white--text"
          >
            <span class="white--text">
              {{ $t("noData") }}
            </span>
          </v-alert>
        </template>
        <template v-slot:item.cost="{ item }">
          <money-format
            :value="item.cost"
            :locale="$i18n.locale"
            :currency-code="currencyCode"
            :supplemental-precision="0"
          />
        </template>
        <template v-slot:item.popular="{ item }">
          {{ item.popular ? $t("yes") : $t("no") }}
        </template>
        <template v-slot:item.description="{ item }">
          <v-main class="ql-editor">
            <span v-html="item.description"></span>
          </v-main>
        </template>
        <template v-slot:item.action="{ item }">
          <tooltip-button
            :icon="'mdi-delete'"
            :action="openWarning"
            :item="item.id"
            :color="'red'"
            :message="'delete'"
          ></tooltip-button>
          <tooltip-button
            :icon="'mdi-pencil'"
            :action="editItem"
            :item="item"
            :color="'secondary'"
            :message="'edit'"
          ></tooltip-button>
        </template>
      </v-data-table>
  </v-main>
</template>
<script>
import axios from 'axios';
import MoneyFormat from 'vue-money-format';
import subscriptionPlansDialog from '@/components/socialvue/dialogs/SubscriptionPlansDialog';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
  name: 'SubscriptionPlans',
  components: { TooltipButton, DeleteWarning, subscriptionPlansDialog, MoneyFormat },
  mixins: [errorDialog],
  data () {
    return {
      showAdd: false,
      loading: false,
      subscriptionPlansList: [],
      editedItem: {},
      itemToDelete: '',
      showDelete: false,
      currencyCode: ''
    };
  },
  created () {
    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
  },
  mounted () {
    this.list();
  },
  computed: {
      headers () {
          return [
              { text: this.$t('name'), value: 'name' },
              { text: this.$t('description'), value: 'description' },
              { text: this.$t('cost'), value: 'cost' },
              { text: this.$t('popular'), value: 'popular' },
              { text: this.$t('actions'), value: 'action', sortable: false }
          ];
      }
  },
  methods: {
    openWarning (item) {
      this.showDelete = true;
      this.itemToDelete = item;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    confirmDelete () {
      this.loading = true;
      const url =
        process.env.VUE_APP_BASE_URL +
        '/api/subscriptions/' +
        this.itemToDelete;
      axios
        .delete(url)
        .then(() => {
          this.list();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
      this.showDelete = false;
    },
    newItem () {
      this.reset();
      this.showAdd = true;
    },
    onAddCallback () {
      this.list();
    },
    onAddClose () {
      this.reset();
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
    },
    reset () {
      this.editedItem = {};
      this.editedItem.name = '';
      this.editedItem.cost = '';
      this.editedItem.description = '';
      this.editedItem.popular = false;
      this.editedItem.isEdit = false;
    },
    editItem (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    },
    async list (page = 1) {
      await axios
        .get(process.env.VUE_APP_BASE_URL + `/api/subscriptions?page=${page}`)
        .then(({ data }) => {
          this.subscriptionPlansList = data.data;
        });
    }
  }
};
</script>
